exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-development-detail-jsx": () => import("./../../../src/pages/development-detail.jsx" /* webpackChunkName: "component---src-pages-development-detail-jsx" */),
  "component---src-pages-developments-jsx": () => import("./../../../src/pages/developments.jsx" /* webpackChunkName: "component---src-pages-developments-jsx" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-templates-development-listing-template-js": () => import("./../../../src/templates/development-listing-template.js" /* webpackChunkName: "component---src-templates-development-listing-template-js" */),
  "component---src-templates-home-template-js": () => import("./../../../src/templates/home-template.js" /* webpackChunkName: "component---src-templates-home-template-js" */),
  "component---src-templates-landing-template-js": () => import("./../../../src/templates/landing-template.js" /* webpackChunkName: "component---src-templates-landing-template-js" */),
  "component---src-templates-listing-template-js": () => import("./../../../src/templates/listing-template.js" /* webpackChunkName: "component---src-templates-listing-template-js" */)
}

